@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./listing/common";
@forward "./listing/related";
@forward "./details/common";
.waf-row-video-details {
  background-color: var(--neutral-100);
  .waf-detail {
    padding-block: var(--space-6);
    @include background(var(--primary-900), "pattern/mobile/video-details-bg.png", center / cover no-repeat);
    .title,
    .icon-b-share {
      @extend %pure-white-900;
    }
    .title {
      font-size: 1.8rem;
      @extend %mt-6;
      @extend %mb-0;
    }
    .meta {
      @extend %primary-50;
    }
    .icon-b-share,
    .share-icon {
      width: auto;
      height: auto;
      @extend %transparent-bg;
    }
    .icon-b-share {
      &::before {
        font-size: 1.8rem;
      }
    }
    .social-share {
      @extend %flex-fe-c;
    }
    .head-wrap {
      @extend %my-3;
    }
    .article {
      &-description {
        order: 1;
        border-top: 0.1rem solid var(--primary-500);
        @extend %pt-4;
        @extend %d-block;
        p {
          line-height: 2.2rem;
          @extend %my-0;
          @extend %font-16-pm;
          @extend %primary-50;
        }
      }
      &-detail {
        @extend %flex-column;
      }
      &-head {
        order: 1;
        @extend %flex-column;
      }
      &-body {
        overflow: hidden;
        @extend %half-radius;
      }
    }
  }
  .waf-listing {
    background-color: transparent;
    @extend %pt-6;
    .head-tab {
      @extend %mb-0;
      li a {
        font-size: 1.4rem;
        @extend %neutral-900;
      }
    }
    .title,
    .article-title,
    .icon-b-share {
      @extend %neutral-900;
    }
    .meta {
      @extend %neutral-700;
    }
    .icon-b-share,
    .share-icon {
      width: auto;
      height: auto;
      @extend %transparent-bg;
    }
    .article {
      &-wrap {
        @extend %gap-3;
      }
      &-thumbnail {
        @extend %relative;
        .item-type-icon {
          width: 2.8rem;
          height: 2.8rem;
          @extend %d-block;
          &::before {
            font-size: 0.8rem;
          }
        }
      }
      &-content {
        @extend %px-0;
        a {
          order: -1;
        }
      }
      &-list {
        .social-share {
          @extend %flex;
        }
      }
      &-info {
        @extend %mt-auto;
        @extend %mb-1;
        @extend %relative;
        @extend %flex-n-fe;
        &::after {
          content: '|';
          top: 0.3rem;
          @extend %neutral-700;
          @extend %mr-1;
          @extend %relative;
        }
        .timestamp p {
          @extend %neutral-700;
          @extend %my-0;
          @extend %mr-1;
          @extend %font-12-pm;
        }
      }
      &-meta {
        width: auto;
      }
    }
  }
}
@include mq(col-md) {
  .waf-row-video-details {
    @include background(var(--primary-900), "pattern/video-details-bg.png", center / cover no-repeat);
    .waf-component {
      padding-block: var(--space-12);
    }
    .waf-detail {
      background: unset;
      padding-inline: var(--space-2) var(--space-1);
      .title {
        margin-top: var(--space-3);
        font-size: 2.2rem;
      }
      .head-wrap {
        margin-block: var(--space-5) var(--space-4);
      }
      .meta {
        font-size: 1.6rem;
      }
      .article-description {
        padding-top: var(--space-3);
        p {
          font-size: 1.6rem;
        }
      }
      .icon-b-share {
        width: 3rem;
        height: 3rem;
        background-color: var(--primary-700);
      }
    }
    .waf-listing {
      padding-inline: var(--space-1) var(--space-2);
      .title,
      .article-title,
      .icon-b-share {
        color: var(--pure-white-900);
      }
      .share-icon {
        padding-bottom: 0.3rem;
        @include flex-config(flex, null, flex-end, flex-end);
      }
      .title {
        font-size: 1.8rem;
      }
      .meta {
        font-size: 1.2rem;
        color: var(--primary-50);
      }
      .icon-b-share {
        &::before {
          font-size: 1.5rem;
        }
      }
      .head-tab a {
        height: 3.8rem;
      }
      .article {
        &-list {
          gap: var(--space-3);
        }
        &-wrap {
          gap: var(--space-2);
        }
        &-info {
          &::after {
            color: var(--primary-500);
            margin-right: var(--space-2);
          }
          .timestamp p {
            color: var(--primary-50);
            margin-right: var(--space-2);
          }
        }
        &-item {
          margin-bottom: var(--space-0);
        }
        &-thumbnail {
          width: 20rem;
        }
      }
    }
    .article {
      &-item {
        margin-bottom: 0;
      }
      &-list {
        grid-template-columns: 1fr;
      }
    }
  }
}
@include mq(col-lg) {
  .waf-row-video-details {
    .waf-detail {
      padding-inline: var(--space-10) var(--space-2);
    }
    .waf-listing {
      padding-inline: var(--space-1) var(--space-10);
      .article {
        &-list {
          gap: var(--space-4);
        }
        &-wrap {
          gap: var(--space-3);
        }
      }
    }
  }
}