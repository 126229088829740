@use "../config/" as *;
.waf-listing {
    --_title-color: var(--neutral-50);
    --_listing-title: 14;
    --_listing-line: 3;
    @extend %primary-900-bg;
    @include listing-card(horizontal);
    .waf-head {
        @extend %mb-4;
    }
    .head-wrap {
        align-items: center;
        margin-bottom: 0;
        li {
            margin-bottom: 0;
        }
    }
    .head-tab a {
        max-width: 12rem;
    }
    .article-content {
        color: var(--neutral-50)
    }
    .article-info,
    .article-thumbnail .item-type-icon {
        @extend %d-none;
    }
    .icon-b-share {
        background-color: transparent;
        justify-content: flex-end;
    }
}
@include mq(col-lg) {
    .waf-listing {
        padding-block: var(--space-8);
    }
}