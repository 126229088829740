@use "../config" as *;
.waf-detail {
  span[class^="icon-b-"] {
    @extend %neutral-200-bg;
  }
  iframe {
    width: 100%;
  }
  .social-wrap {
    @extend %flex-n-c;
    @extend %gap-2;
  }
  .social-items {
    @extend %flex-n-c;
    @extend %gap-2;
  }
  .icon-b-close,
  .icon-b-share {
    width: 3rem;
    height: 3rem;
    @extend %neutral-800;
    @extend %neutral-200-bg;
    @extend %flex-c-c;
    @extend %circle-radius;
  }
  .icon-b-share::before {
    @include icon(share, 14);
  }
  .icon-b-close::before {
    @include icon(close, 14);
  }
  p,
  li,
  td,
  blockquote,
  span {
    line-height: 2rem;
    @extend %font-14-pm;
  }
  p {
    @extend %my-3;
  }
  a,
  strong {
    font-weight: 700;
  }
  .sub-title {
    @extend %font-18-pb;
    @extend %mb-2;
  }
  ol,
  ul {
    padding-left: var(--space-4);
    @extend %mb-2;
  }
  li {
    @extend %mb-1;
  }
  .tagline,
  .article-description,
  .meta-author,
  .meta-category,
  .views-section,
  .detail-container,
  .article-tags,
  .article-commentbox,
  .item-type-icon,
  .share-label,
  .social-share-wrap {
    @extend %d-none;
  }
}
.article {
  &-head {
    .head-wrap {
      @extend %flex-sb-c;
      @extend %mb-3;
    }
    .title {
      @extend %font-20-pb;
      @extend %mb-2;
    }
    .meta {
      @extend %font-14-pm;
      @extend %neutral-800;
    }
  }
}
@include mq(col-md) {
  .waf-detail {
    p,
    li,
    td,
    blockquote,
    span {
      line-height: 2.2rem;
      font-size: 1.6rem;
    }
    ol,
    ul {
      margin-bottom: var(--space-3);
    }
  }
  .article {
    &-head {
      .title {
        font-size: 3.2rem;
        margin-bottom: var(--space-4);
      }
      .meta {
        font-size: 1.6rem;
        line-height: 2.2rem;
      }
      .head-wrap {
        margin-bottom: var(--space-6);
      }
      .close {
        display: none;
      }
      .social-share.active {
        .share-icon {
          display: none;
        }
        .social-share-wrap {
          @include flex-config(flex, row, center, center);
        }
        .close {
          display: flex;
        }
      }
    }
  }
}
@include mq(col-xl) {
  .waf-detail {
    .icon-b-share::before {
      font-size: 1.8rem;
    }
  }
}